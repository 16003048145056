<template>
  <v-dialog v-model="isDialogVisible" max-width="1250px" :fullscreen="isFullScreen" >
    <v-card class="_pa-sm-8" ref="refDialog">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Расчет соответствия</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="isFullScreen = !isFullScreen"><v-icon>mdi-window-maximize</v-icon></v-btn>
          <v-btn icon dark @click="isDialogVisible = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <SelectClients v-model="showSelectClients" @ok="clientsSelected" />
      <ShowProfgramsResult v-model="showCalcComplianceDialog" :clients="clientsForCalc" />

      <v-dialog v-model="addDialog" max-width="600">
        <v-card>
          <v-btn class="button-close ma-4" icon @click="addDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-card-title />
          <v-card-text>Добавить профессиограмму или раздел</v-card-text>
            <v-treeview open-all return-object dense :items="visibleChapters">
              <template v-slot:label="{ item }">
                <div :class="canAdd(item) ? 'item-normal' : 'item-disabled'" @click="addItem(item)">{{ item.name }}</div>
              </template>
            </v-treeview>
        </v-card>
      </v-dialog>
      <div v-if="loading.positions || loading.profs" class="h-100 my-7 d-flex justify-center align-center">
        <v-progress-circular :size="50" color="primary" indeterminate />
      </div>
      <div v-else class="setup-compliance-card" ref="setupCard">
          <div class="row mt-1">
            <div class="col-6 _mt-1" style="width: 360px">
              <v-select v-model="report.position" :items="positions" item-text="title" return-object dense :loading="loading.positions"
                label="Должность:" _outlined hide-details :menu-props="{ maxHeight: '400' }" />
            </div>
            <div class="col-6">
              <v-select  item-value="id" v-model="report.calcType" :items="calcTypes" label="Метод расчета" _outlined dense hide-details />
            </div>
          </div>

          <div class="row mt-1 relative mb-7">
            <div class="col mb-2">
              <div class="_text-caption mb-2 _blue--text">Должности, на которые нужно тестировать кандидатов:</div>
              <div v-if="!report.professiograms.length && !report.chapters.length" class="text-caption my-2 grey--text">Список пуст</div>
              <div v-else class="d-flex flex-wrap">
                <div v-for="p in report.professiograms" :key="p.id" >
                  <v-chip class="mr-2 mb-2" close color="primary"  label text-color="primary" outlined @click:close="delProfFromReport(p.id)">
                    {{p.name}}
                  </v-chip>
                </div>
                <div v-for="p in report.chapters" :key="p.id" >
                  <v-chip class="mr-2 mb-2" close color="blue lighten-5" text-color="primary" label @click:close="delChapterFromReport(p.id)">
                    {{p.name}}
                  </v-chip>
                </div>
              </div>

              <v-btn class="mx-0 " fab dark small absolute bottom left color="primary" @click="addDialog=true">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>

          <v-divider class="mt-1" />

          <div class="mt-3 d-flex">
            <v-btn _v-if="$store.getters['auth/isAllowed']('run_testresultscompliencereport')"
              class="_w-100 mb-3 px-8" color="primary" :disabled="!canCalc" @click="calc()">
              {{ loading.calc ? 'Остановить расчет' : 'Рассчитать' }}
            </v-btn>
            <div class="ml-4 mt-2" style="width: 200px">
              <v-select  item-value="id" v-model="calcBlock" :items="calcBlocks" label="Кол-во кандидатов в 1 расчете" _outlined dense hide-details />
            </div>
            <v-spacer />
            <v-btn _v-if="$store.getters['auth/isAllowed']('download_testresultscompliencereport')"
              class="mb-3 mr-3" color="primary" :disabled="!candidates2show.length" :loading="loading.excel" @click="toExcelShort()">
              Выгрузка в Excel (краткая)
            </v-btn>
            <!-- <v-btn v-if="$store.getters['auth/isAllowed']('download_testresultscompliencereport')"
              class="mb-3" color="primary" :loading="loading.loadResults" @click="toExcelFull()">
              Выгрузка в Excel (полная)
            </v-btn> -->
            <v-btn _v-if="$store.getters['auth/isAllowed']('download_testresultscompliencereport')"
              class="mb-3" color="primary" :loading="loading.loadResults" @click="showFullReport()">
              Смотреть полный расчет
            </v-btn>
          </div>
          <!-- <div class="error-text">{{errors.calc}}</div> -->
          <!-- <div class="d-flex align-center">
            <div class="mr-1 d-none d-sm-block">Показывать:</div>
            <v-select v-model="selectedHeaders" :items="headers2select"
              multiple outlined dense hide-details style="width: 420px; max-width:80vw;" @change="changeSelectedHeaders"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 2" small><span>{{ item.text ? item.text : item.text2 }}</span></v-chip>
                <span v-if="index === 2" class="grey--text caption">(+еще {{ selectedHeaders.length - 2 }})</span>
              </template>
              <template v-slot:item="{ active, item, attrs }">
                <div class="d-flex align-center w-100">
                  <div><v-checkbox hide-details class="ma-0 mb-1" :input-value="(attrs.inputValue)"></v-checkbox></div>
                  <div class="d-flex align-center  justify-space-between w-100">
                    <div>{{item.text ? item.text : item.text2}}</div>
                  </div>
                </div>
              </template>
            </v-select>
          </div> -->

      </div>

      <v-progress-linear style="color:#fff" :value="progress.cur/progress.total*100-5 " height="35" v-if="loading.calc">
        Расчет: {{ progress.cur }} из {{ progress.total }} кандидатов
      </v-progress-linear>

      <v-data-table
        :headers="allHeaders"
        :items="candidates2show"
        show-select v-model="candidatesForExport"
        :custom-sort="customSort"
        class="px-0 _px-sm-5 elevation-1"
        sort-by="id" sort-desc must-sort
        :loading="loading.calc" loading-text="Идет расчет... Пожалуйста подождите"
        :mobile-breakpoint="300" :items-per-page="-1" :height="tableHeight" fixed-header hide-default-footer
      >
        <template v-slot:item.fullname="{ item }">
          <div class="d-flex _justify-space-between align-center">

            <v-tooltip bottom v-if="item.error" color="error">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="error" dark v-bind="attrs" v-on="on" class="mr-2">mdi-alert-circle-outline</v-icon>
              </template>
              <span>{{item.error}}</span>
            </v-tooltip>

            <router-link class="text-primary mr-2" :to="{ name: 'candidates-id', params: { id: item.id } }">
              <template v-if="item.last_name || item.first_name">{{ item.last_name }} {{ item.first_name }}</template>
              <template v-else>{{ item.id }}</template>
            </router-link>
          </div>
        </template>
        <template v-slot:item.email="{ item }">{{item.email}}</template>

        <template v-slot:no-data> Кандидатов не найдено. </template>
      </v-data-table>

    </v-card>
  </v-dialog>

</template>

<script>
/* eslint-disable */
import SelectClients from '@/components/admins/profile/SelectClients';
import ShowProfgramsResult from '@/components/candidates/calc/ShowProfgramsResult';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'CalcSetupCompliance',
  components: {
    SelectClients, ShowProfgramsResult
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: Boolean,
  },
  data: () => ({
    tableHeight: 20,
    addDialog: false,
    isFullScreen: false,
    candidatesForExport: [],
    report: {
      professiograms: [],
      chapters: [],
      position: 0,
      calcType: 'ranges',
    },
    calcTypes: [{ id: 'peaks', text: "По пикам" }, { id: 'ranges', text: "По диапазонам" }],
    calcBlock: 10,
    calcBlocks: [{ id: 1, text: "1" }, { id: 2, text: "2" }, { id: 5, text: "5" }, { id: 10, text: "10" }, { id: 50, text: "50" }, { id: 100, text: "100" }],
    headers: [
      { text: 'Фамилия и Имя', value: 'fullname', width: '280px',},
      { text: 'Электронная почта', value: 'email', },
      { text: 'Возраст', value: 'age', },
      { text: 'Должность', value: 'position', },
    ],
    selectedHeaders: [],

    clientsForCalc: [],
    showSelectClients: false,
    showCalcComplianceDialog: false,
  }),
  watch: {
    async value(val) {
      if (val) {
        this.setTableHeight();
        if (!this.positions.length)
          await this.loadPositions();
        if (!this.profs.length || !this.chapters.length)
          await this.loadProfs();
      }
    },
    isFullScreen(val) {
      setTimeout(()=>{
        this.setTableHeight();
      }, 400)
    },
  },
  computed: {
    ...mapGetters({
      progress: 'profgrams/bulkcalc/progress',
      profs: 'profgrams/bulkcalc/profs',
      chapters: 'profgrams/bulkcalc/chapters',
      positions: 'profgrams/bulkcalc/positions',
      loading: 'profgrams/bulkcalc/loading',
      errors: 'profgrams/bulkcalc/errors',
      selectedCandidates: 'users/candidates/selected',
      candidatesResult: 'profgrams/bulkcalc/candidatesResult',
      candidatesCalc: 'profgrams/bulkcalc/candidatesCalc',
      colsCalc: 'profgrams/bulkcalc/columns',
      user: 'auth/user',
    }),
    visibleChapters() {
      return this.chapters.filter(el => this.$store.getters['profgrams/chapters/isChapterVisible'](el, this.user, false))
    },
    isDialogVisible: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('input', false);
        }
      },
    },
    canCalc() {
      return (this.report.professiograms.length || this.report.chapters.length) && this.report.position
    },
    candidates2show() {
      return this.candidatesCalc // .filter(el => el.compliances);
    },
    headers2select() {
      return [...this.allHeaders];
    },
    showHeaders() {
      return this.allHeaders.filter(s => this.selectedHeaders.includes(s.value));
    },
    clientId() {
      return this.user && this.user.user_type==='CorpClient' ? this.user.id : 0
    },
    allHeaders() {
      return [
        ...this.headers,
        ...this.colsCalc.map(el => ({
          text: el.name,
          value: el.id.toString()
        }))
      ];
      // return this.headers.filter(s => this.selectedHeaders.includes(s.value));
    },

  },
  methods: {
    ...mapActions({
      loadPositions: 'profgrams/bulkcalc/loadPositions',
      loadProfs: 'profgrams/bulkcalc/loadProfs',
      calcProfs: 'profgrams/bulkcalc/calc',
      exportToExcelShort: 'profgrams/bulkcalc/exportToExcelShort',
      exportToExcelFull: 'profgrams/bulkcalc/exportToExcelFull',
      loadResult: 'profgrams/bulkcalc/loadResult',
    }),
    ...mapMutations({
      stopCalcProfs: 'profgrams/bulkcalc/stopCalc',
    }),
    setTableHeight() {
        if (this.isFullScreen) {
          const setupCard = this.$refs.setupCard
          const height = document.documentElement.clientHeight - 75 - setupCard.clientHeight;
          this.tableHeight = Math.max(height, 400);
        }
        else
          this.tableHeight = 400;
    },
    isChapterAdded(chapterId) {
      return this.report.chapters.find((ch) => ch.id==chapterId)
    },
    isProfAdded(id) {
      return this.report.professiograms.find((ch) => ch.id==id)
    },
    canAdd(item) {
      return item.children ? !this.isChapterAdded(item.id) : (!this.isProfAdded(item.id))
    },
    delProfFromReport(id) {
      this.report.professiograms = this.report.professiograms.filter(el => el.id !== id)
    },
    delChapterFromReport(id) {
      this.report.chapters = this.report.chapters.filter(el => el.id !== id)
    },
    customSort(items, [index], [isDesc]) {
      items.sort((a, b) => {
        if (index === 'fullname') {
          const aname = a.last_name || a.first_name ? `${a.last_name} ${a.first_name}` : 'Я';
          const bname = b.last_name || b.first_name ? `${b.last_name} ${b.first_name}` : 'Я';
          return !isDesc ? aname.localeCompare(bname) : bname.localeCompare(aname);
        }
        if (typeof(a[index])=='string' || typeof(b[index])=='string') {
          const va = a[index] ? a[index] : ''
          const vb = b[index] ? b[index] : ''
          return !isDesc ? va.localeCompare(vb) : vb.localeCompare(va);
        }
        const va = a[index] ? a[index] : 0
        const vb = b[index] ? b[index] : 0
        return isDesc ? va - vb : vb - va
      });
      return items;
    },
    addItem(item) {
      if (!this.canAdd(item))
        return
      if (item.children) {
        this.report.chapters.push({ name: item.name, id: item.id })
      } else {
        this.report.professiograms.push({ name: item.name, id: item.id })
      }
      this.addDialog = false
    },
    changeSelectedHeaders() {
      localStorage.setItem('showCandidateColumnsCompliance', JSON.stringify(this.selectedHeaders));
    },

    async calc() {
        if (this.loading.calc) {
          this.stopCalcProfs(true)
          return;
        }

        const payload = {
          candidates: this.selectedCandidates,
          // corp_client: this.user.id,
          professiograms: this.report.professiograms.map(el => el.id),
          chapters: this.report.chapters.map(el => el.id),
          method: this.report.calcType, // peaks или ranges
          position: this.report.position.id,
        }
        const response = await this.calcProfs({payload, calcBlockSize: this.calcBlock} );
    },
    async toExcelShort() {
      await this.exportToExcelShort({
          candidates: this.candidatesForExport.length ? this.candidatesForExport : this.candidates2show,
          filename: "Кандидаты"
        })
    },
    async toExcelFull() {
      if (this.clientId) {
        this.clientsForCalc = [this.clientId]
        await this.transerToExcelFull()
      } else {
        this.showSelectClients = true
      }
    },

    showFullReport() {
      if (this.clientId) {
        this.clientsForCalc = [this.clientId]
        this.showCalcComplianceDialog = true
      } else {
        this.showSelectClients = true
      }

    },

    async clientsSelected(clients) {
      this.clientsForCalc = clients;
      this.showCalcComplianceDialog = true
      // await this.transerToExcelFull()
    },

    async transerToExcelFull() {
        await this.loadResult({ corp_clients: this.clientsForCalc })
        await this.exportToExcelFull({
          candidates: this.candidatesResult,
          filename: "Кандидаты"
          // filename: this.report.position.title
        })
    },

  },
  async mounted() {
    this.selectedHeaders = JSON.parse(localStorage.getItem('showCandidateColumnsCompliance'));
    if (!this.selectedHeaders) {
      this.selectedHeaders = this.allHeaders.map(s => s.value);
    }
  }
};
</script>
<style scoped lang="scss">
@import "src/assets/styles/variables";

.setup-compliance-card {
  // border: 1px solid #ccc;
  border-radius: 10px;
  padding: 16px;
}

.item-normal {
    cursor: pointer;
}
.item-disabled {
    color: #888;
}

.v-chip {
  white-space: normal;
  height: auto !important;
  min-height: 32px;
}
.v-chip .v-chip__content {
  height: auto;
  word-break: break-word;
}
.error-text {
   color: red;
   font-size: 12px;
}

</style>
